<template>
    <div>
        <invoice-form :entity-id="entityId"></invoice-form>
    </div>
</template>

<script>
import InvoiceForm from '@/components/pages/invoices/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        InvoiceForm,
    },
    mixins: [dirtyForm],

    data() {
        return {
            entityId: null,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.entityId = route.params.entityId
            },
        },
    },
}
</script>
